import React, { useState } from "react";
import classnames from "classnames";
import { ArrowS, CloseBig, Menu } from "../../1-atoms/Icons/24 ";
import IconButton from "../../1-atoms/Buttons/IconOnly";
import NavLinksMobile from "./NavLinksMobile";
import OutsideClickHandler from "react-outside-click-handler";
import { useLocation } from "react-router";
import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";

function NestedList({
  title,
  items,
}: {
  title: string;
  items: { title: string; url: string }[];
}) {
  const [open, setOpen] = useState(false);
  let location = useLocation();
  const toggleList = () => setOpen(!open);
  const closeList = () => setOpen(false);

  return (
    <OutsideClickHandler onOutsideClick={closeList}>
      <div
        className={classnames("typography-xs-bold relative", {
          "text-black050": open,
        })}
      >
        <button
          className={classnames("typography-xs-bold p-0 hover:text-purple100", {
            "text-purple200": items.filter(
              (item) => item.url === location?.pathname
            ).length,
          })}
          onClick={toggleList}
        >
          <div className={classnames("ml-1 capitalize")}>{title}</div>
          <ArrowS className={classnames({ "rotate-180": open })} />
        </button>
        {open && (
          <ul
            className={classnames(
              "white-card shadow absolute left-0 z-20 translate-y-4 transform whitespace-nowrap !shadow-shadow2"
            )}
          >
            {items.map((item, ix) => (
              <li
                key={item.title + item.url}
                className={"px-6 py-3 hover:cursor-pointer hover:text-purple100"}
              >
                <Link
                  onClick={() => setOpen(false)}
                  to={item.url}
                  className={classnames({
                    "text-purple200": item.url === location.pathname,
                  })}
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
}

function PageHeader({
                      internship,
                      className,
                      open = false,
                      ...props
                    }: {
  open?: boolean;
  internship?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) {
  const [isOpen, setIsOpen] = useState(open);

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  const location = useLocation();
  const {t} = useTranslation();

  const profileLinks = [
    {title: `${t("page-header:generalInfo")}`, url: "/dashboard/general"},

    {title: `${t("page-header:identity")}`, url: "/dashboard/identity"},
    {
      title: `${t("page-header:general-office-hours")}`,
      url: "/dashboard/general-office-hours",
    },
    {
      title: `${t("page-header:feesTypesTherapy")}`,
      url: "/dashboard/fees-and-types-of-therapy",
    },
    {
      title: `${t("page-header:practiceDetails")}`,
      url: "/dashboard/practice-details",
    },
    {
      title: `${t("page-header:specialtyExpertise")}`,
      url: "/dashboard/specialties-and-expertise",
    },
    {
      title: `${t("page-header:educationCertification")}`,
      url: "/dashboard/education-and-certifications",
    },
    {
      title: `${t("page-header:therapistProfileQuestions")}`,
      url: "/dashboard/questions",
    },
    {title: `${t("page-header:gallery")}`, url: "/dashboard/gallery"},
    {
      title: `${t("page-header:videos")}`,
      url: "/dashboard/videos",
    },
    {
      title: internship
          ? `${t("page-header:supervision")}`
          : `${t("page-header:licensesTitlesStates")}`,
      url: internship ? "/dashboard/supervision" : "/dashboard/licenses",
    },
  ].filter(Boolean);
  return (
      <>
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <header
              data-testid={"page-header"}
              className={classnames(
                  "typography-xs page-container flex min-h-[3.5rem] items-center border-b border-grey200 bg-whitefff text-black100",
                  "laptop:justify-center laptop:px-4 laptop:py-5 desktop:px-0",
                  "below-laptop:relative below-laptop:z-50 below-laptop:px-4 below-laptop:py-0"
              )}
              {...props}
          >
            <div className={"mx-auto flex w-full max-w-[71.25rem]"}>
              <IconButton
                  data-testid={"menu-button"}
                  label={isOpen ? "closeButton" : "openButton"}
                  onClick={toggleMenu}
                  className={classnames(
                      "my-3 p-1 text-gray350 md:my-5 laptop:hidden"
                  )}
              >
                {isOpen ? <CloseBig/> : <Menu/>}
              </IconButton>

              <Link
                  data-testid={"main-logo"}
                  to={"/dashboard/general"}
                  aria-label="Go to the main page"
                  className="mr-auto flex h-[31px] w-[123px] items-center below-laptop:m-auto below-laptop:justify-self-center laptop:h-[38px] laptop:w-[380px]"
              >
                <div className={"hidden laptop:flex"}>
                  <img
                      data-testid={"logo"}
                      width="166"
                      height="40"
                      src="/Choosing_Therapy_Primary_Logo.svg"
                      alt="Choosing Therapy"
                  />
                </div>
                <div className={"flex laptop:hidden"}>
                  <img
                      data-testid={"logo"}
                      width="123"
                      height="31"
                      src="/Choosing_Therapy_Primary_Logo.svg"
                      alt="Choosing Therapy"
                  />
                </div>
                <span className="visually-hidden">
                Visit the Choosing Therapy main page
              </span>
              </Link>

              <nav
                  data-testid={"desktop-nav-bar"}
                  aria-label={"navigation"}
                  className="desktop:w-initial m-auto ml-auto below-laptop:hidden laptop:z-50 laptop:w-full"
              >
                <ul className={"flex space-x-8 laptop:justify-end"}>
                  {(location.pathname?.includes("dashboard/") ||
                      location.pathname?.includes("/account-settings")) && (
                      <>
                        <li>
                          <NestedList
                              title={t("page-header:profile")}
                              items={profileLinks}
                          />
                        </li>

                        <li className={"flex items-center"}>
                          <Link
                              to={"/account-settings"}
                              className={classnames(
                                  "typography-xs-bold py-0 hover:text-purple100",
                                  {
                                    "text-purple200":
                                        "/account-settings" === location?.pathname,
                                  }
                              )}
                          >
                            {t("page-header:accountSettings")}
                          </Link>
                        </li>
                      </>
                  )}
                  <li className={"flex items-center"}>
                    <a
                        href={"https://choosingtherapyhelp.zendesk.com/hc/en-us"}
                        className={classnames(
                            "typography-xs-bold py-0 hover:text-purple100"
                        )}
                        target={"_blank"}
                        rel="noreferrer"
                    >
                      {t("page-header:helpCenter")}
                    </a>
                  </li>
                  {(location.pathname?.includes("dashboard/") ||
                      location.pathname?.includes("/account-settings") ||
                      location.pathname?.includes("/information")) && (
                      <li className={"flex items-center"}>
                        <Link
                            to={"/logout"}
                            className={classnames(
                                "typography-xs-bold py-0 hover:text-purple100"
                            )}
                        >
                          {t("page-header:logout")}
                        </Link>
                      </li>
                  )}
                </ul>
              </nav>
              <NavLinksMobile
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  profileSubLinks={profileLinks}
              />
            </div>
          </header>
        </OutsideClickHandler>
        {isOpen && (
            <div
                className="fixed left-0 top-0 z-40 h-full w-full bg-black000 bg-opacity-30 backdrop-opacity-90 laptop:hidden"/>
        )}
      </>
  );
}

export default PageHeader;
