import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgPinterestNew = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.4244 0C30.5822 0 32.334 1.75188 32.334 3.90963V28.0904C32.334 30.2481 30.5821 32 28.4244 32H12.161C12.8383 30.8577 13.6986 29.241 14.0419 27.921C14.25 27.1196 15.1089 23.8496 15.1089 23.8496C15.6673 24.9146 17.2989 25.8161 19.0344 25.8161C24.2001 25.8161 27.9218 21.0658 27.9218 15.1631C27.9218 9.50513 23.3045 5.27156 17.3632 5.27156C9.97205 5.27156 6.0468 10.2331 6.0468 15.6357C6.0468 18.1478 7.38411 21.275 9.52323 22.2708C9.84773 22.4218 10.0213 22.3552 10.096 22.0417C10.1528 21.8036 10.4416 20.6404 10.5717 20.0994C10.6133 19.9264 10.5927 19.7777 10.4529 19.6081C9.74523 18.7501 9.1783 17.1717 9.1783 15.6996C9.1783 11.9222 12.0387 8.26706 16.912 8.26706C21.1193 8.26706 24.066 11.1342 24.066 15.2349C24.066 19.8681 21.7262 23.0778 18.6819 23.0778C17.0007 23.0778 15.7423 21.6876 16.1457 19.9828C16.6287 17.9469 17.5642 15.7499 17.5642 14.2804C17.5642 12.9649 16.8583 11.8678 15.3972 11.8678C13.6784 11.8678 12.2981 13.6458 12.2981 16.0273C12.2981 17.5441 12.8105 18.5701 12.8105 18.5701C12.8105 18.5701 11.1133 25.7467 10.8022 27.0833C10.4579 28.5619 10.5927 30.6446 10.7425 31.9998L10.7432 32H4.24361C2.08586 32 0.333984 30.2481 0.333984 28.0904V3.90963C0.333984 1.75188 2.0858 0 4.24361 0L28.4244 0Z" fill="#A4B3FF"/>
    </svg>
    
  );
};
export default SvgPinterestNew;
