import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgFacebook = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0904 0C30.2482 0 32 1.75188 32 3.90963V28.0904C32 30.2481 30.2481 32 28.0904 32H21.4361V19.9428H25.5986L26.3906 14.779H21.4361V11.4279C21.4361 10.0153 22.1282 8.63819 24.3474 8.63819H26.6V4.24194C26.6 4.24194 24.5556 3.89306 22.6011 3.89306C18.5206 3.89306 15.8536 6.36612 15.8536 10.8433V14.779H11.3178V19.9428H15.8536V32H3.90963C1.75188 32 0 30.2481 0 28.0904V3.90963C0 1.75188 1.75181 0 3.90963 0L28.0904 0Z" fill="#A4B3FF"/>
    </svg>
  );
};
export default SvgFacebook;
