import * as React from 'react';
import { SVGProps } from 'react';
const SvgYoutubeNew = (props: SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_28_132)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0904 0C30.2482 0 32 1.75188 32 3.90963V28.0904C32 30.2481 30.2481 32 28.0904 32H3.90963C1.75188 32 0 30.2481 0 28.0904V3.90963C0 1.75188 1.75181 0 3.90963 0L28.0904 0ZM26.7799 10.9769C26.5212 10.0088 25.7589 9.24644 24.7908 8.98775C23.0359 8.5175 16 8.5175 16 8.5175C16 8.5175 8.96406 8.5175 7.20919 8.98775C6.24131 9.24644 5.47881 10.0088 5.22006 10.9769C4.75 12.7316 4.75 16.3926 4.75 16.3926C4.75 16.3926 4.75 20.0536 5.22006 21.8081C5.47881 22.7762 6.24131 23.5387 7.20919 23.7974C8.96406 24.2675 16 24.2675 16 24.2675C16 24.2675 23.0359 24.2675 24.7908 23.7974C25.7589 23.5387 26.5212 22.7762 26.7799 21.8081C27.25 20.0536 27.25 16.3926 27.25 16.3926C27.25 16.3926 27.25 12.7316 26.7799 10.9769ZM13.7499 19.7677V13.0176L19.5955 16.3926L13.7499 19.7677Z" fill="#A4B3FF"/>
        </g>
        <defs>
            <clipPath id="clip0_28_132">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
export default SvgYoutubeNew;
