import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgTwitterNew = function (props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M28.666 32H4.66602C2.45802 32 0.666016 30.208 0.666016 28V4C0.666016 1.792 2.45802 0 4.66602 0H28.666C30.874 0 32.666 1.792 32.666 4V28C32.666 30.208 30.874 32 28.666 32ZM12.954 24.128C20.498 24.128 24.626 17.872 24.626 12.456C24.626 12.28 24.626 12.104 24.618 11.928C25.418 11.352 26.114 10.624 26.666 9.8C25.93 10.128 25.138 10.344 24.306 10.448C25.154 9.944 25.802 9.136 26.114 8.176C25.322 8.648 24.442 8.984 23.506 9.168C22.754 8.368 21.69 7.872 20.514 7.872C18.25 7.872 16.41 9.712 16.41 11.976C16.41 12.296 16.45 12.608 16.514 12.912C13.106 12.744 10.082 11.104 8.05802 8.624C7.70602 9.232 7.50602 9.936 7.50602 10.688C7.50602 12.112 8.23402 13.368 9.33002 14.104C8.65802 14.08 8.02602 13.896 7.47402 13.592V13.648C7.47402 15.632 8.89002 17.296 10.762 17.672C10.418 17.768 10.058 17.816 9.68202 17.816C9.41802 17.816 9.16202 17.792 8.91402 17.744C9.43402 19.376 10.954 20.56 12.746 20.592C11.338 21.696 9.57002 22.352 7.65002 22.352C7.32202 22.352 6.99402 22.336 6.67402 22.296C8.48202 23.448 10.642 24.128 12.954 24.128Z" fill="#A4B3FF"/>
    </svg>
  );
};
export default SvgTwitterNew;
