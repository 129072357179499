import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgTiktok = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.3565 0H3.97554C1.87497 0 0.166016 1.70895 0.166016 3.80952V28.1905C0.166016 30.291 1.87497 32 3.97554 32H28.3565C30.4571 32 32.166 30.291 32.166 28.1905V3.80952C32.166 1.70895 30.4571 0 28.3565 0ZM25.3134 13.9604C25.1405 13.9764 24.9653 13.987 24.7877 13.987C22.7893 13.987 21.0331 12.9592 20.0114 11.4057C20.0114 15.4811 20.0114 20.1181 20.0114 20.1958C20.0114 23.7836 17.1024 26.6926 13.5146 26.6926C9.92678 26.6926 7.01783 23.7836 7.01783 20.1958C7.01783 16.608 9.92678 13.699 13.5146 13.699C13.6502 13.699 13.7828 13.7112 13.9161 13.7196V16.9211C13.7828 16.9051 13.6517 16.8808 13.5146 16.8808C11.683 16.8808 10.1988 18.365 10.1988 20.1966C10.1988 22.0282 11.683 23.5124 13.5146 23.5124C15.3462 23.5124 16.9637 22.0693 16.9637 20.2377C16.9637 20.1653 16.9957 5.30895 16.9957 5.30895H20.0555C20.3435 8.04495 22.5523 10.2042 25.3134 10.4023V13.9604Z" fill="#A4B3FF"/>
    </svg>
  );
};
export default SvgTiktok;
