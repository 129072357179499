import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useLocation } from "react-router";
import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { ArrowS } from "~/UI/1-atoms/Icons/24 ";

export let handle = {
  i18n: "page-header",
};

function NavLinksMobile({
  isOpen,
  setIsOpen,
  profileSubLinks,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  profileSubLinks: { title: string; url: string }[];
}) {
  const { t } = useTranslation("page-header");
  const location = useLocation();
  const [open, setOpen] = useState("/account-settings" !== location?.pathname);
  const toggleList = () => setOpen(!open);
  useEffect(() => {
    if ("/account-settings" === location?.pathname) setOpen(false);
  }, [location.pathname]);

  return (
    <div>
      {isOpen && (
        <nav
          data-testid={"mobile-nav-bar"}
          className={classnames(
            { "below-desktop:hidden": !isOpen },
            "absolute left-0 top-full z-50 flex h-fit w-full flex-col space-y-5 space-x-0 border-t border-grey200 bg-whitefff tablet:h-screen tablet:w-[20rem] tablet:space-y-6 desktop:hidden"
          )}
        >
          <ul className={"my-6 flex flex-col"}>
            {(location.pathname?.includes("dashboard/") ||
              location.pathname?.includes("/account-settings")) && (
              <>
                <li
                  className={classnames("px-4 py-2 tablet:px-7 tablet:py-3", {
                    "bg-gray100 text-black000": open,
                  })}
                >
                  <button
                    className={classnames(
                      "typography-lg flex  w-full flex-row justify-between p-0 ",
                      {
                        "text-purple200": profileSubLinks.filter(
                          (item) => item.url === location?.pathname
                        ).length,
                      }
                    )}
                    onClick={toggleList}
                  >
                    <span>Profile</span>
                    <ArrowS className={classnames({ "-rotate-90": !open })} />
                  </button>
                </li>
                {open && (
                  <ul
                    className={"mt-5 mb-2 flex flex-col space-y-5 tablet:mb-5"}
                  >
                    {profileSubLinks.map((sublink) => (
                      <li
                        key={sublink.title}
                        id={sublink.title}
                        className={
                          "typography-base-bold px-4 text-black050 tablet:px-7"
                        }
                      >
                        <Link
                          to={sublink.url}
                          onClick={() => setIsOpen(false)}
                          className={classnames("hover:cursor-pointer ", {
                            "text-purple200": sublink.url === location?.pathname,
                          })}
                        >
                          {sublink.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
                <li
                  className={
                    "flex items-center px-4 py-2 tablet:px-7 tablet:py-3"
                  }
                >
                  <Link
                    to={"/account-settings"}
                    className={classnames("typography-lg py-0 ", {
                      "text-purple200":
                        "/account-settings" === location?.pathname,
                    })}
                    onClick={() => setIsOpen(false)}
                  >
                    {t("accountSettings")}
                  </Link>
                </li>
              </>
            )}
            <li
              className={"flex items-center px-4 py-2 tablet:px-7 tablet:py-3"}
            >
              <a
                href={"https://choosingtherapyhelp.zendesk.com/hc/en-us"}
                className={classnames("typography-lg py-0 ")}
                onClick={() => setIsOpen(false)}
                target={"_blank"}
                rel="noreferrer"
              >
                {t("helpCenter")}
              </a>
            </li>
            {(location.pathname?.includes("dashboard/") ||
              location.pathname?.includes("/account-settings") ||
              location.pathname.includes("information")) && (
              <li
                className={
                  "flex items-center px-4 py-2 tablet:px-7 tablet:py-3"
                }
              >
                <Link
                  to={"/logout"}
                  className={classnames("typography-lg py-0 ")}
                  onClick={() => setIsOpen(false)}
                >
                  {t("logout")}
                </Link>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
}

export default NavLinksMobile;
