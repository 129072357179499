import type { PageFooterProps } from "./index";

export const mockProps: PageFooterProps = {
  socialMediaLinks: [
    {
      type: "youtube",
      url: "https://www.youtube.com/channel/UCHEpPGGSL8WewHMUVuQekpA",
    },
    {
      type: "tikTok",
      url: "https://www.tiktok.com/@choosingtherapy",
    },
    {
      type: "facebook",
      url: "https://www.facebook.com/ChoosingTherapy/",
    },
    {
      type: "twitter",
      url: "https://twitter.com/ChooseTherapy",
    },
    {
      type: "linkedin",
      url: "https://www.linkedin.com/company/choosing-therapy/",
    },
    {
      type: "instagram",
      url: "https://www.instagram.com/choosingtherapy/",
    },
    {
      type: "pinterest",
      url: "https://www.pinterest.com/Choosing_Therapy/",
    },
  ],
  tosLinks: [
    {
      title: "Terms of Service",
      url: `https://www.choosingtherapy.com/terms-of-service/`,
    },
    {
      title: "Privacy Policy",
      url: `https://www.choosingtherapy.com/privacy-policy/`,
    },
    {
      title: "Editorial Policy",
      url: `https://www.choosingtherapy.com/editorial-policy/`,
    },
    {
      title: "Advertising Policy",
      url: `https://www.choosingtherapy.com/advertising-policy/`,
    },
  ],
};
