import styles from "./styles/app.css";
import type { LoaderFunction, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
"@remix-run/react";
import PageHeader from "~/UI/3-organisms/PageHeader";
import PageFooter from "~/UI/3-organisms/PageFooter";
import React from "react";
import i18n from "./i18n.server";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import client from "~/lib/graphql-client";
import { createHeaders } from "~/services/session.server";
import type { GetFooterDataQuery } from "~/generated/graphql";
import { withSentry } from "@sentry/remix";

type ClientEnv = {
  SENTRY_DSN: string;
  SENTRY_TRACE_RATE: number;
  SENTRY_ENVIRONMENT: string;
  SENTRY_RELEASE: string;
};

type LoaderData = {
  locale: string;
  directoryUrl?: string;
  showInternship?: boolean;
  staticPagesLinks?: GetFooterDataQuery["footer"];
  clientEnv?: ClientEnv;
};

const CheckInternship = gql`
  query TherapistInternship {
    me {
      therapist {
        id
        firstName
        middleName
        lastName
        slug
        status
        licenses {
          id
        }
        internship {
          id
        }
      }
      errors {
        __typename
        ... on BaseError {
          message
        }
      }
    }
  }
`;

const GetFooter = gql`
  query GetFooterData {
    footer {
      sections {
        links {
          link
          name
        }
        name
        viewAll {
          link
          name
        }
      }
    }
  }
`;

export let loader: LoaderFunction = async ({ request }) => {
  let locale = await i18n.getLocale(request);

  const res = await client.query({
    query: CheckInternship,
    fetchPolicy: "network-only",
    context: { headers: await createHeaders(request) }
  });
  const { me } = res.data;

  const isEmptyBothLicenseAndInternship =
  !me?.therapist?.internship && !me?.therapist?.licenses?.length;
  const isFilledInternshipAndEmptyLicenses =
  me?.therapist?.internship && !me?.therapist?.licenses?.length;

  const { data: footerData } = await client.
  query<GetFooterDataQuery>({
    query: GetFooter,
    fetchPolicy: "network-only"
  }).
  catch((error) => {
    return {
      data: {
        footer: { sections: [] }
      }
    };
  });

  return json<LoaderData>({
    showInternship: Boolean(
      isFilledInternshipAndEmptyLicenses || isEmptyBothLicenseAndInternship
    ),
    locale,
    directoryUrl: process.env.DIRECTORY_DOMAIN,
    staticPagesLinks: { sections: footerData?.footer?.sections || [] },
    clientEnv: {
      SENTRY_DSN: process.env.SENTRY_DSN || "",
      SENTRY_TRACE_RATE: process.env.SENTRY_TRACE_RATE ?
      parseFloat(process.env.SENTRY_TRACE_RATE) :
      0,
      SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT || "development",
      SENTRY_RELEASE: process.env.VERCEL_GIT_COMMIT_SHA || ""
    }
  });
};

export let handle = {
  // In the handle export, we could add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  i18n: [
  "general",
  "general-info",
  "educations-certifications",
  "page-header",
  "page-footer",
  "login",
  "fees",
  "question",
  "videos",
  "specialties",
  "office-hours",
  "practice-details"]

};

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "Choosing Therapy | Therapist Dashboard",
  viewport: "width=device-width,initial-scale=1"
});

export function links() {
  return [{ rel: "stylesheet", href: styles }];
}

function App() {
  // Get the locale from the loader
  let { locale, directoryUrl, showInternship, staticPagesLinks, clientEnv } =
  useLoaderData<LoaderData>();

  let { i18n } = useTranslation();
  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <Meta />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link
          rel="apple-touch-icon"
          type="image/svg+xml"
          href="/Choosing_Therapy_Favicon_for_Apple_Touch_Icon.svg" />


        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true" />

        <link
          href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap"
          rel="stylesheet" />

        <Links />
      </head>
      <body>
        <div className={"flex min-h-screen flex-col"}>
          <PageHeader internship={showInternship} />
          <Outlet />
          <PageFooter
            staticPagesLinks={staticPagesLinks}
            directoryUrl={directoryUrl}
            className={"mt-auto"} />

        </div>
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(clientEnv)}`
          }} />

        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export default withSentry(App);