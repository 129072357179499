import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import * as React from 'react';
import { SVGProps } from 'react';
const SvgLinkedinNew = function (props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
        <path d="M30.1319 0C31.4366 0 32.4991 1.03305 32.4991 2.30728V29.691C32.4991 30.9652 31.4366 32 30.1319 32H2.86021C1.5581 32 0.5 30.9652 0.5 29.691V2.30728C0.5 1.03305 1.5581 0 2.86021 0H30.1319ZM27.7682 27.2673V18.8924C27.7682 14.7795 26.8802 11.6175 22.0747 11.6175C19.7656 11.6175 18.217 12.8836 17.5841 14.0841H17.5191V11.9965H12.9696V27.2673H17.71V19.7144C17.71 17.7222 18.0869 15.7921 20.5564 15.7921C22.9921 15.7921 23.0225 18.0703 23.0225 19.8412V27.2673H27.7682ZM9.9922 11.9965H5.24129V27.2673H9.9922V11.9965ZM7.61799 4.40537C6.09369 4.40537 4.86457 5.63849 4.86457 7.15761C4.86457 8.67709 6.09369 9.91015 7.61799 9.91015C9.13629 9.91015 10.3689 8.67709 10.3689 7.15761C10.3689 5.63849 9.13629 4.40537 7.61799 4.40537Z" fill="#A4B3FF"/>
    </svg>

  );
};
export default SvgLinkedinNew;
